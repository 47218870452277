<template>
  <div class="avatar ml-4">
    <v-file-input
      accept="image/png, image/jpeg, image/bmp"
      :placeholder="$t('auth.profile.avatar.placeholder')"
      prepend-icon="mdi-camera"
      :label="$t('auth.profile.avatar.label')"
      :show-size="1000"
      @change="onFileSelect"
    />

    <v-dialog
      content-class="v-dialog--overlay"
      data-test-id="modal"
      v-model="cropperDialog"
      width="500"
      eager
    >
      <v-card>
        <v-card-text>
          <div class="cropper">
            <img class="cropper__image" :height="400" ref="imageCropper" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn data-test-id="crop" class="primary" @click="handleCropClick">{{
            $t('auth.profile.avatar.crop')
          }}</v-btn>
          <v-btn data-test-id="cancel" color="primary" text @click="handleCancelClick">{{
            $t('auth.profile.avatar.cancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import VALIDATORS from '@/helpers/forms/validators.helper';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'AvatarCropper',
  data: () => ({
    imageSrc: null,
    cropperDialog: false,
    cropper: null,
    avatarRules: VALIDATORS.AVATAR,
  }),
  methods: {
    onFileSelect(file) {
      if (!file) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      const fileReader = new FileReader();

      fileReader.addEventListener(
        'load',
        () => {
          this.imageSrc = fileReader.result;
          this.cropper = new Cropper(this.$refs.imageCropper, {
            aspectRatio: 1,
            minCropBoxWidth: 256,
            minCropBoxHeight: 256,
            viewMode: 2,
            dragMode: 'move',
            movable: false,
            zoomable: false,
            background: false,
            cropBoxMovable: true,
            cropBoxResizable: true,
          });
          this.cropper.replace(this.imageSrc);
        },
        false,
      );

      fileReader.readAsDataURL(file);

      this.cropperDialog = true;
    },
    saveImage() {
      this.cropper
        .getCroppedCanvas({
          width: 256,
          height: 256,
        })
        .toBlob((blob) => {
          this.$emit('save-avatar', blob);
        }, 'image/jpeg');
    },
    handleCropClick() {
      this.saveImage();
      this.cropperDialog = false;
      this.cropper.destroy();
    },
    handleCancelClick() {
      this.cropperDialog = false;
      this.cropper.destroy();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.avatar {
  width: 100%;
  flex: 4;
}

.cropper {
  padding-top: 30px;
  max-width: 100%;

  &__image {
    width: 100%;
  }
}
</style>
